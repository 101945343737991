import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// pages
import {
  Home,
  News,
  Dashboard,
  CustomerReports,
  CustomerUploads,
  AdminReports,
  AdminUploads,
} from "@/pages";

// components
import { BaseLayout, RouteGuard } from "@/components";

// types
import { USER_ROLE } from "@/types";

const App = () => (
  <BrowserRouter>
    <Routes>
      {/* Home Page */}
      <Route path="/" element={<Home />} />

      {/* Auth Pages */}
      <Route element={<BaseLayout />}>
        {/* Common */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* Admin */}
        <Route element={<RouteGuard roles={[USER_ROLE.ADMIN]} />}>
          <Route path="/admin/reports" element={<AdminReports />} />
          <Route path="/admin/uploads" element={<AdminUploads />} />
        </Route>

        {/* Customer */}
        <Route element={<RouteGuard roles={[USER_ROLE.CUSTOMER]} />}>
          <Route path="/news" element={<News />} />
          <Route path="/intel-hub/reports" element={<CustomerReports />} />
          <Route path="/intel-hub/uploads" element={<CustomerUploads />} />
        </Route>
      </Route>

      {/* Not Found Pages */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);

export default App;
