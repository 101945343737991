import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  layout: {
    minHeight: "100vh",
  },
  header: {
    boxShadow: `0 2px 4px 0 ${theme.colors.colorFillSecondary}`,
  },
  content: {
    minHeight: 120,
    padding: 32,
  },
  footer: {
    textAlign: "center",
    color: theme.colors.colorText,
    backgroundColor: theme.colors.colorPrimaryBg,
    boxShadow: `inset 0 106px 36px -116px ${theme.colors.colorFill}`,
  },
}));

export { useStyles };
