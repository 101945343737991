/* eslint-disable max-len */
import { FC } from "react";
import cx from "clsx";

// types
import { GlyphIcon } from "./shared";

// styles
import { useStyles } from "./shared/styles";

const AnonymousOutlined: FC<GlyphIcon> = ({
  className,
  color = "currentColor",
  onClick,
}) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || ""]: className,
      })}
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      onClick={onClick}
    >
      <g fill={color} fillRule="nonzero">
        <path d="M8.572 13.071a.5.5 0 0 1 .492.41l.008.09v1.072a3 3 0 0 1-2.824 2.995l-.176.005H5a1.93 1.93 0 0 1-1.923-1.778l-.005-.15V13.57a.5.5 0 0 1 .992-.09l.008.09v2.143c0 .476.358.869.82.923l.108.006h1.072a2 2 0 0 0 1.994-1.85l.006-.15V13.57a.5.5 0 0 1 .5-.5M11.429 13.071a.5.5 0 0 0-.492.41l-.008.09v1.072a3 3 0 0 0 2.824 2.995l.176.005H15a1.93 1.93 0 0 0 1.923-1.778l.006-.15V13.57a.5.5 0 0 0-.992-.09l-.008.09v2.143a.93.93 0 0 1-.82.923l-.109.006H13.93a2 2 0 0 1-1.995-1.85l-.005-.15V13.57a.5.5 0 0 0-.5-.5M12.854 3.071c.976 0 1.655.652 1.926 1.636l1.42 5.88a.5.5 0 0 1-.943.321l-.029-.085-1.379-5.715c-.147-.675-.494-1.037-.995-1.037-.271 0-.447.084-.737.349l-.236.225c-.615.595-1.086.856-1.88.856-.726 0-1.22-.226-1.786-.707l-.425-.383c-.283-.251-.45-.34-.649-.34-.388 0-.663.263-.829.892l-.039.165-1.504 5.714a.5.5 0 0 1-.982-.165l.015-.09 1.499-5.69c.242-1.143.88-1.826 1.84-1.826.5 0 .864.194 1.313.592l.277.253c.468.424.776.585 1.27.585.433 0 .685-.116 1.056-.453l.25-.24c.533-.514.92-.737 1.547-.737" />
        <path d="M17.862 10.214a.5.5 0 0 1 .09.992l-.09.008H2.142a.5.5 0 0 1-.089-.992l.09-.008zM15.03 7.357a.5.5 0 0 1 .09.992l-.09.008H5.041a.5.5 0 0 1-.09-.992l.09-.008zM17.862 13.071a.5.5 0 0 1 .09.992l-.09.008H2.142a.5.5 0 0 1-.089-.992l.09-.008z" />
      </g>
    </svg>
  );
};

export default AnonymousOutlined;
