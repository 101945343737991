import { createUseStyles } from "react-jss";
import alpha from "color-alpha";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    color: theme.colors.colorText,
    padding: 20,
  },
  statsPanel: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: 40,
  },
  headingCard: {
    width: 360,
    height: "auto",
    border: "none",
    "& > .ant-card-body": {
      padding: "20px 10px",
    },
  },
  headingCardDesc: {
    "&.ant-typography": {
      color: alpha(theme.colors.colorBrandWhite, 0.59),
      fontSize: 16,
      lineHeight: "1.3em",
      fontWeight: 400,
    },
  },
  headingCardTitle: {
    "&.ant-typography": {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: "1.1em",
      color: theme.colors.colorBrandWhite,
    },
  },
  statsCards: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: 20,
    animation: "$fadeIn 1s ease-in-out",
  },
  statsCard: {
    width: 300,
    height: 240,
    backgroundColor: "transparent",
    backgroundImage: `linear-gradient(
      180deg,
      ${theme.colors.colorBgElevated} 0%,
      ${alpha(theme.colors.colorBgElevated, 0.1)}
      100%
    )`,
    border: "none",
    transition: "background 0.3s",
    "& > .ant-card-body": {
      padding: 40,
      display: "flex",
      flexDirection: "column",
    },
    "&:hover": {
      backgroundColor: alpha(theme.colors.colorBgElevated, 0.49),
    },
  },
  chatterStatsCard: {
    "& h1": {
      textTransform: "capitalize",
    },
  },
  statsIcon: {
    "& > svg": {
      width: "auto",
      height: 42,
      color: theme.colors.colorText,
    },
  },
  statsValue: {
    "&.ant-typography": {
      marginTop: 20,
      marginBottom: 0,
      fontSize: 42,
      fontWeight: 600,
      lineHeight: 1.25,
    },
  },
  statsTitle: {
    "&.ant-typography": {
      marginTop: 20,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.3,
      color: alpha(theme.colors.colorBrandWhite, 0.59),
    },
  },
  statsSup: {
    paddingLeft: 8,
    fontWeight: 400,
    fontSize: 24,
  },
  statsTextSuccess: {
    "&.ant-typography": {
      color: theme.colors.colorSuccess,
    },
  },
  statsTextDanger: {
    "&.ant-typography": {
      color: theme.colors.colorError,
    },
  },
  statsTextInfo: {
    "&.ant-typography": {
      color: theme.colors.colorBrandBlue200,
    },
  },
  ...theme.animations,
}));

export { useStyles };
