const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || "dev";

const getConfig = () => {
  switch (ENV) {
    case "prod":
      return {
        api: {
          baseUrl: "https://api.omegablack.io/api/v1",
          timeout: 60000,
        },
        auth0: {
          domain: "auth.omegablack.io",
          audience: "https://api.omegablack.com",
          clientId: "A3DED3rNHpadmZkuB1btBv3y2ZQTVWVx",
          redirectUri: `${window.location.origin}/dashboard`,
          returnTo: window.location.origin,
          adminOrgId: "org_A3BEfTZqa0m8e5sc",
        },
        azure: {
          storageAccountName: "omegablack",
        },
        reCaptcha: {
          siteKey: "6LehwBApAAAAAOGHYJyMxfRtuEQHyq3LbqbSRXmQ",
        },
      };
    case "dev":
      return {
        api: {
          baseUrl: "https://dev.api.omegablack.io/api/v1",
          timeout: 60000,
        },
        auth0: {
          domain: "omegablackdev.us.auth0.com",
          audience: "https://api.omegablackdev.com",
          clientId: "QZFGRtokDfYDBSSNED4BHPfrnGjvdiee",
          redirectUri: `${window.location.origin}/dashboard`,
          returnTo: window.location.origin,
          adminOrgId: "org_BJE2cFSsdptLybDe",
        },
        azure: {
          storageAccountName: "devomegablack",
        },
        reCaptcha: {
          siteKey: "6LehwBApAAAAAOGHYJyMxfRtuEQHyq3LbqbSRXmQ",
        },
      };
    case "local":
    default:
      return {
        api: {
          baseUrl: "http://192.168.1.126:8080/api/v1",
          timeout: 60000,
        },
        auth0: {
          domain: "omegablackdev.us.auth0.com",
          audience: "https://api.omegablackdev.com",
          clientId: "QZFGRtokDfYDBSSNED4BHPfrnGjvdiee",
          redirectUri: `${window.location.origin}/dashboard`,
          returnTo: window.location.origin,
          adminOrgId: "org_BJE2cFSsdptLybDe",
        },
        azure: {
          storageAccountName: "devomegablack",
        },
        reCaptcha: {
          siteKey: "6LehwBApAAAAAOGHYJyMxfRtuEQHyq3LbqbSRXmQ",
        },
      };
  }
};

export default getConfig();
