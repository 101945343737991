import {
  DotChartOutlined,
  AlertOutlined,
  NotificationOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import cx from "clsx";

// components
import { Typography, Card } from "@/components/atoms";

// helpers
import { toNumberWithSign } from "@/helpers";

// constants
import { weeklyStatsData as stats } from "./Dashboard.contants";

// styles
import { useStyles } from "./Dashboard.styles";

const { Title, Text } = Typography;

const Dashboard = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.statsPanel}>
        <Card className={styles.headingCard}>
          <Text className={styles.headingCardDesc}>Dark Web Intel</Text>
          <Title className={styles.headingCardTitle}>
            Weekly Stats from
            <br />
            the Dark Web
          </Title>
        </Card>
        <div className={styles.statsCards}>
          <Card className={cx(styles.statsCard, styles.chatterStatsCard)}>
            <DotChartOutlined className={styles.statsIcon} />
            <Title
              className={cx({
                [styles.statsValue]: true,
                [styles.statsTextSuccess]: stats.chatter === "low",
                [styles.statsTextDanger]: stats.chatter === "high",
              })}
            >
              {stats.chatter}
            </Title>
            <Text className={styles.statsTitle}>Dark Web Chatter (Volume)</Text>
          </Card>
          <Card className={styles.statsCard}>
            <AlertOutlined className={styles.statsIcon} />
            <Title className={cx(styles.statsValue, styles.statsTextInfo)}>
              {stats.thirdAlerts.toLocaleString()}
            </Title>
            <Text className={styles.statsTitle}>3rd & 4th Party Alerts</Text>
          </Card>
          <Card className={styles.statsCard}>
            <NotificationOutlined className={styles.statsIcon} />
            <Title className={cx(styles.statsValue, styles.statsTextInfo)}>
              {stats.breachAlerts.toLocaleString()}
            </Title>
            <Text className={styles.statsTitle}>Breach Alerts</Text>
          </Card>
          <Card className={styles.statsCard}>
            <SafetyOutlined className={styles.statsIcon} />
            <Title
              className={cx({
                [styles.statsValue]: true,
                [styles.statsTextSuccess]: stats.malwareFamiliesMargin > 0,
                [styles.statsTextDanger]: stats.malwareFamiliesMargin < 0,
              })}
            >
              {stats.malwareFamilies.toLocaleString()}
              {stats.malwareFamiliesMargin === 0 || (
                <sup className={styles.statsSup}>
                  {toNumberWithSign(stats.malwareFamiliesMargin)}
                </sup>
              )}
            </Title>
            <Text className={styles.statsTitle}>Malware Families</Text>
          </Card>
        </div>
      </div>
    </div>
  );
};

export { Dashboard };
