/* eslint-disable max-len */
import { FC } from "react";
import cx from "clsx";

// types
import { GlyphIcon } from "./shared";

// styles
import { useStyles } from "./shared/styles";

const SearchOutlined: FC<GlyphIcon> = ({
  className,
  color = "currentColor",
  onClick,
}) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || ""]: className,
      })}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M18.85 16.44L14.85 12.44C14.7204 12.3074 14.5762 12.19 14.42 12.09L13.42 11.4C14.4187 10.1643 14.9672 8.62567 14.9756 7.0369C14.984 5.44813 14.4516 3.90378 13.466 2.65764C12.4804 1.41151 11.1002 0.537731 9.55222 0.179908C8.00425 -0.177915 6.38059 0.00150388 4.9481 0.68868C3.5156 1.37586 2.35948 2.5299 1.66974 3.96117C0.980003 5.39244 0.797677 7.01576 1.15273 8.56437C1.50778 10.113 2.37908 11.4947 3.62345 12.4826C4.86782 13.4704 6.41121 14.0055 8 14C9.58804 14.0005 11.1291 13.461 12.37 12.47L13.12 13.47C13.209 13.5988 13.3094 13.7193 13.42 13.83L17.42 17.83C17.4665 17.8769 17.5218 17.9141 17.5827 17.9395C17.6436 17.9649 17.709 17.9779 17.775 17.9779C17.841 17.9779 17.9064 17.9649 17.9673 17.9395C18.0282 17.9141 18.0835 17.8769 18.13 17.83L18.83 17.13C18.9214 17.0396 18.9744 16.9175 18.9782 16.789C18.9819 16.6605 18.936 16.5356 18.85 16.44ZM8 12C7.01109 12 6.04439 11.7068 5.22215 11.1574C4.3999 10.608 3.75904 9.82707 3.3806 8.91344C3.00216 7.99981 2.90314 6.99448 3.09607 6.02457C3.289 5.05467 3.7652 4.16375 4.46446 3.46449C5.16373 2.76523 6.05464 2.28903 7.02455 2.0961C7.99445 1.90317 8.99978 2.00219 9.91341 2.38063C10.827 2.75907 11.6079 3.39993 12.1573 4.22217C12.7068 5.04442 13 6.01112 13 7.00003C13 8.32611 12.4732 9.59788 11.5355 10.5356C10.5978 11.4732 9.32608 12 8 12Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchOutlined;
