/* eslint-disable max-len */
import { FC } from "react";
import cx from "clsx";

// types
import { GlyphIcon } from "./shared";

// styles
import { useStyles } from "./shared/styles";

const FootprintOutlined: FC<GlyphIcon> = ({
  className,
  color = "currentColor",
  onClick,
}) => {
  const classes = useStyles();
  return (
    <svg
      className={cx({
        [classes.root]: true,
        [className || ""]: className,
      })}
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      onClick={onClick}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.38 2.199c.533-.64 1.283-1.032 2.265-1.032.97 0 1.693.363 2.168.995.454.605.638 1.4.682 2.205.087 1.61-.378 3.5-.82 4.74a.5.5 0 0 1-.41.328l-4.156.52a.5.5 0 0 1-.552-.398c-.266-1.332-.459-3.35-.144-5.059.157-.854.45-1.68.966-2.3Zm.075 6.705c-.204-1.234-.31-2.863-.059-4.225.142-.768.388-1.405.751-1.84.347-.415.817-.672 1.498-.672.692 0 1.103.242 1.368.595.285.38.445.948.483 1.659.071 1.309-.278 2.894-.664 4.06l-3.377.423ZM15.649 5.316c-.534-.64-1.284-1.033-2.266-1.033-.97 0-1.693.364-2.167.996-.455.605-.639 1.4-.683 2.205-.087 1.61.378 3.5.821 4.74a.5.5 0 0 0 .409.328l4.156.52a.5.5 0 0 0 .552-.398c.266-1.332.459-3.35.144-5.059-.157-.855-.45-1.68-.966-2.3Zm-.076 6.705c.204-1.234.31-2.864.059-4.225-.142-.769-.388-1.405-.751-1.84-.347-.416-.817-.673-1.498-.673-.692 0-1.103.243-1.368.596-.285.38-.445.948-.483 1.659-.071 1.309.278 2.894.664 4.06l3.377.423ZM8.141 10.521a.5.5 0 0 1 .543.359c.187.654.333 1.675.139 2.615-.099.476-.29.954-.63 1.35-.347.402-.826.694-1.45.818-.635.127-1.188.022-1.646-.264-.445-.278-.758-.702-.978-1.147-.437-.883-.572-1.98-.572-2.715a.5.5 0 0 1 .438-.497l4.156-.519Zm-3.576 1.455c.044.589.179 1.284.45 1.832.17.343.375.595.612.743.225.14.515.213.92.132.415-.083.693-.264.888-.49.2-.233.334-.54.408-.9.118-.568.076-1.206-.023-1.724l-3.255.407ZM11.887 13.638a.5.5 0 0 0-.543.359c-.187.654-.333 1.675-.139 2.615.099.476.29.954.63 1.35.347.402.826.693 1.45.818.635.127 1.188.022 1.646-.264.445-.278.758-.702.978-1.147.437-.883.572-1.981.572-2.716a.5.5 0 0 0-.438-.496l-4.156-.52Zm3.576 1.455c-.044.588-.179 1.284-.45 1.832-.17.343-.375.595-.612.743-.225.14-.515.213-.92.132-.415-.083-.693-.264-.887-.49-.201-.233-.335-.54-.41-.9-.117-.568-.075-1.206.024-1.724l3.255.407Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default FootprintOutlined;
